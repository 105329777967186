<template>
  <BaseDocumentationCard
    v-model="documentation"
    :resource="client"
    :delete-end-point="deleteEndPoint"
    :update-end-point="updateEndPoint"
    :observations="client ? client.observations : ''"
  />
</template>

<script>
import BaseDocumentationCard from '@/components/ui/card/BaseDocumentationCard.vue'

export default {
  name: 'ClientDocumentationCard',
  components: { BaseDocumentationCard },
  props: {
    client: {
      type: Object,
      default: null,
    }
  },
  computed: {
    documentation: {
      get() {
        if (!this.client) {
          return []
        }

        return this.client.documentation
      },
      set(value) {
        this.client.documentation = value
      },
    },
    deleteEndPoint() {
      return this.client ? `/clients/delete_doc/${this.client.id}` : null
    },
    updateEndPoint() {
      return this.client ? `/clients/${this.client.id}` : null
    },
  },
}


</script>