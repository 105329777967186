<template>
  <div>
    <BaseTable
      ref="jobs-table"
      :columns="columns"
      :totals-configuration="totalsConfiguration"
      :filters="baseFilters"
      :excluded-filters="excludedFilters"
      :resource="$data.$constants.RESOURCES.RESOURCE_JOBS"
      :resourceStore="'job'"
      :loading="loading"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
      @row-clicked="$router.push({ name: 'viewJob', params: { id: $event.id } })"
    >
      <template #cell(job_date)="data">
        {{ data.item.job_date ? new Date(data.item.job_date).toLocaleDateString() : '' }}
      </template>
      <template #cell(division)="data">
        <span> {{ data.item.division.name }} </span>
      </template>
      <template #cell(client)="data">
        <span> {{ data.item.client.name }} </span>
      </template>
      <template #cell(project)="data">
        <span> {{ data.item.project.name }} </span>
      </template>
      <template #cell(user)="data">
        <span> {{ data.item.user.name }} </span>
      </template>
      <template #cell(status)="data">
        <StatusBadge :status="data.item.status.name" />
      </template>
      <template #cell(live_prevision)="data">
        <span> {{ data.item.live_prevision | numberToLocalString }} €</span>
      </template>
      <template #cell(total_sale)="data">
        <span> {{ data.item.total_sale | numberToLocalString }} €</span>
      </template>
      <template #cell(actions)="data">
        <b-row
          no-gutters
          align-v="center"
          align-h="center"
        >
          <b-col>
            <b-link
              :to="{ name: 'viewJob', params: { id: data.item.id} }"
              class="pr-1 d-inline-block text-indigo"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Ver"
                icon="EyeIcon"
                size="18"
              />
            </b-link>
            <b-link  v-if="isSuperAdmin"
              class="d-inline-block text-danger"
              @click="deleteJob(data.item)"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Eliminar"
                icon="TrashIcon"
                size="18"
              />
            </b-link>
          </b-col>
        </b-row>
      </template>
    </BaseTable>
    <JobListFilters
      ref="job-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('job/setFilters', $event)"
    />
  </div>
</template>

<script>
import JobsApi from '@/api/jobs-api'
import BaseTable from '@/components/ui/table/BaseTable.vue'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import JobListFilters from '@/components/jobs/filters/JobListFilters.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'JobsTable',
  components: { JobListFilters, StatusBadge, BaseTable },
  props: {
    clientId: {
      type: String,
      default: null,
    },
    projectId: {
      type: String,
      default: null,
    },
    excludedFilters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      totalsConfiguration: [
        { colspan: 8 },
        { label: 'VENTA TOTAL', key: 'total_sale', unit: '€' },
        { label: 'PREVISIÓN VIVA', key: 'live_prevision', unit: '€' },
        { colspan: 1 },
      ],
      filtersVisible: false,
    }
  },
  computed: {
    ...mapGetters({
      filters: 'job/getFilters',
    }),
    baseFilters() {
      return { client_id: this.clientId, project_id: this.projectId, ...this.filters }
    },
    columns() {
      const result = [
        {
          label: 'FECHA',
          key: 'job_date',
          sortable: true,
        },
        {
          label: 'DIVISIÓN',
          key: 'division',
          sortable: true,
        },
        {
          label: 'P.MANAGER',
          key: 'user',
          sortable: true,
        },
        {
          label: 'REFERENCIA',
          key: 'code',
          sortable: true,
        },
        {
          label: 'NOMBRE',
          key: 'name',
          sortable: true,
        },
        {
          label: 'ESTADO',
          key: 'status',
          sortable: true,
        },
      ]
      if (!this.clientId) {
        result.push(
          {
            label: 'CLIENTE',
            key: 'client',
            sortable: true,
          },
        )
      }

      if (!this.projectId) {
        result.push({
          label: 'OBRA',
          key: 'project',
          sortable: true,
        })
      }

      return result.concat([
        {
          label: 'VENTA',
          key: 'total_sale',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { 'min-width': '130px' },
        },
        {
          label: 'PREVISIÓN VIVA',
          key: 'live_prevision',
          sortable: true,
          thClass: 'text-right',
          tdClass: 'text-right',
          thStyle: { 'min-width': '130px' },
        },
        {
          label: 'ACCIONES',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '150px' },
        },
      ])
    },
    isSuperAdmin() {
      return JSON.parse(localStorage.getItem('userData')).roles[0].name === 'super_admin'
    },
  },
  methods: {
    handleClearListFilters() {
      this.$store.commit('job/setFilters', {})
      this.$refs['job-list-filters'].clearFilters()
    },
    async deleteJob(job) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${job.name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      try {
        this.loading = true
        await JobsApi.delete(job.id)
        await this.$refs['jobs-table'].loadData()
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
