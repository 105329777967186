<template>
  <BaseCard
    actions-visible
    :resource="$data.$constants.RESOURCES.RESOURCE_CLIENTS"
    :footer-visible="false"
    @edit-icon-click="$router.push({ name: 'editClient', params: { id: client.id } })"
    @delete-confirmation="handleDeleteConfirmation"
  >
    <template #title>
      <b-img
        v-if="client && client.image"
        :src="client.image.path"
        :height="80"
        alt="profile image"
      />
      <span class="text-dark h3 mb-0 font-weight-bold">{{ client ? client.name : '' }}</span>
      <StatusBadge
        :status="client ? client.status.name : null"
        class="mx-1 font-small-3"
      />
      <div>
        <small class="font-weight-bold text-transform-none">Fecha de registro:</small>
        <div>
          <small class="text-light font-weight-light">
            {{ client && client.record_date? client.record_date : 'Sin fecha de registro' }}
          </small>
        </div>
      </div>
    </template>
    <ProfilePanel :profile="clientProfile" />
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import StatusBadge from '@/components/status/badge/StatusBadge.vue'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  name: 'ClientProfileCard',
  components: { BaseCard, ProfilePanel, StatusBadge },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    clientProfile() {
      if (!this.client) {
        return []
      }

      return [
        { label: 'Número', value: this.client.number || 'Sin número' },
        { label: 'Nombre fiscal', value: this.client.name || 'Sin nombre fiscal' },
        { label: 'Nombre comercial', value: this.client.commercial_name || 'Sin nombre comercial' },
        { label: 'Dirección postal', value: this.client.address || 'Sin dirección postal' },
        { label: 'CP', value: this.client.post_code || 'Sin código postal' },
        { label: 'Ciudad', value: this.client.population || 'Sin ciudad' },
        { label: 'Provincia', value: this.client.province ? this.client.province.name : 'Sin provincia' },
        { label: 'País', value: this.client.country ? this.client.country.name : 'Sin país' },
        { label: 'CIF', value: this.client.cif || 'Sin CIF' },
        { label: 'Teléfono', value: this.client.phone || 'Sin teléfono' },
        { label: 'EMAIL', value: this.client.email || 'Sin email' },
        { label: 'Descripción / Observaciones', value: this.client.observations || 'Sin Información', type: 'observations' },
      ]
    },
  },
  methods: {
    async handleDeleteConfirmation() {
      if (!this.client) {
        return
      }

      try {
        this.setLoading(true)
        await ApiRestService.delete(this.client.id, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        await this.$router.push({ name: 'clients' })
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>

</style>
