<template>
  <div>
    <ClientProfileCard :client="client" />
    <ClientDocumentationCard :client="client" />
    <ClientBillingCard :client="client" />
    <ClientStatusRiskCard :client="client" />
    <ClientAssociatedResourceTabs :client-id="clientId" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ApiRestService from '@/api/base-api'
import ClientBillingCard from '@/components/clients/card/ClientBillingCard.vue'
import ClientProfileCard from '@/components/clients/card/ClientProfileCard.vue'
import ClientStatusRiskCard from '@/components/clients/card/ClientStatusRiskCard.vue'
import ClientAssociatedResourceTabs from '@/components/clients/tabs/ClientAssociatedResourceTabs.vue'
import ClientDocumentationCard from '@/components/clients/card/ClientDocumentationCard.vue'

export default {
  components: {
    ClientStatusRiskCard,
    ClientProfileCard,
    ClientDocumentationCard,
    ClientBillingCard,
    ClientAssociatedResourceTabs,
  },
  data() {
    return {
      client: null,
      clientId: this.$route.params.id.toString(),
    }
  },
  mounted() {
    this.loadClient()
  },
  methods: {
    ...mapActions('app', ['setLoading']),
    async loadClient() {
      try {
        this.setLoading(true)
        const response = await ApiRestService.get(this.$route.params.id, this.$data.$constants.RESOURCES.RESOURCE_CLIENTS)
        this.client = response.data
      } finally {
        this.setLoading(false)
      }
    },
  },
}
</script>

<style scoped>
</style>
