<template>
  <BaseTable
    ref="contacts-table"
    :resource="$data.$constants.RESOURCES.RESOURCE_CONTACTS"
    :columns="columns"
    :filters="baseFilters"
    :loading="loading"
    :excluded-filters="excludedFilters"
    hide-totals
    hide-filter-button
    hide-printer-button
    hide-upload-button
    @filter-button-click="filtersVisible = !filtersVisible"
    @on-clear-active-filters="handleClearListFilters"
    @row-clicked="$router.push({ name: 'viewContact', params: { id: $event.id } })"
  >
    <template #cell(actions)="data">
      <span>
        <b-link
          :to="{ name: 'viewContact', params: { id: data.item.id} }"
          class="d-inline-block text-indigo"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Ver"
            icon="EyeIcon"
            size="18"
          />
        </b-link>
        <b-link
          v-access="{
            resource: $data.$constants.RESOURCES.RESOURCE_CONTACTS,
            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
          }"
          :to="{ name: 'editContact', params: { id: data.item.id } }"
          class="d-inline-block px-1 text-indigo"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Editar"
            icon="Edit2Icon"
            size="18"
          />
        </b-link>
        <b-link
          v-access="{
            resource: $data.$constants.RESOURCES.RESOURCE_CONTACTS,
            resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
          }"
          class="d-inline-block text-danger"
          @click="deleteContact(data.item)"
        >
          <feather-icon
            v-b-tooltip.hover 
            title="Eliminar"
            icon="TrashIcon"
            size="18"
          />
        </b-link>
      </span>
    </template>
  </BaseTable>
</template>

<script>
import ApiRestService from '@/api/base-api'
import BaseTable from '@/components/ui/table/BaseTable.vue'

export default {
  name: 'ContactsTable',
  components: { BaseTable },
  props: {
    clientId: {
      type: String,
      default: null,
    },
    providerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      currentPage: 1,
      pageLength: 10,
      pages: ['5', '10', '25', '50'],
      columns: [
        {
          label: 'Nombre',
          key: 'name',
          sortable: true,
          thStyle: { 'min-width': '250px' },
        },
        {
          label: 'Apellidos',
          key: 'surname',
          sortable: true,
          thStyle: { 'min-width': '250px' },
        },
        {
          label: 'Cargo',
          key: 'position',
          sortable: true,
        },
        {
          label: 'Email',
          key: 'email',
          sortable: true,
        },
        {
          label: 'Teléfono',
          key: 'phone',
          sortable: true,
        },
        {
          label: 'Observaciones',
          key: 'observations',
          sortable: true,
        },
        {
          label: 'Acciones',
          key: 'actions',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { 'min-width': '200px' },
        },
      ],
      loading: false,
      filtersVisible: false,
      filters: null,
    }
  },
  computed: {
    excludedFilters() {
      const result = []
      if (this.clientId) {
        result.push('client_id')
      }

      if (this.providerId) {
        result.push('provider_id')
      }

      return result
    },
    baseFilters() {
      return { provider_id: this.providerId, client_id: this.clientId, ...this.filters }
    },
  },
  methods: {
    handleClearListFilters() {
      this.filters = {}
    },
    async deleteContact(contact) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${contact.name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ApiRestService.delete(contact.id, this.$data.$constants.RESOURCES.RESOURCE_CONTACTS)
        await this.$refs['contacts-table'].loadData()
        this.$toast('Contacto borrado con éxito')
      } catch (error) {
        this.$toast.error('Ocurrió un error al borrar el contacto. Por favor, inténtelo de nuevo mas tarde.')
        console.error(error)
      }
      this.loading = false
    },
  },
}
</script>

<style scoped>
</style>
