<template>
  <b-tabs pills>
    <b-tab
      :disabled="!offersTabVisible"
      title="Ofertas"
    >
      <BaseCard
        v-if="offersTabVisible"
        title="ofertas"
        :footer-visible="false"
        actions-visible
      >
        <template #actions>
          <b-link :to="{ name: 'createOffer', query: { 'client-id': clientId } }">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
            >
              + Añadir oferta
            </b-button>
          </b-link>
        </template>
        <OffersTable :client-id="clientId" />
      </BaseCard>
    </b-tab>
    <b-tab
      :disabled="!jobsTabVisible"
      title="Jobs"
    >
      <BaseCard
        v-if="jobsTabVisible"
        title="jobs"
        :footer-visible="false"
        actions-visible
      >
        <template #actions>
          <b-link :to="{ name: 'createJob', query: { 'client-id': clientId } }">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
            >
              + Añadir job
            </b-button>
          </b-link>
        </template>
        <JobsTable
          :client-id="clientId"
          :excluded-filters="['client_id']"
        />
      </BaseCard>
    </b-tab>
    <b-tab
      :disabled="!saleInvoicesTabVisible"
      title="Facturas"
    >
      <BaseCard
        v-if="saleInvoicesTabVisible"
        title="Facturas emitidas"
        :footer-visible="false"
        actions-visible
      >
        <template #actions>
          <b-link :to="{ name: 'createSaleInvoice', query: { 'client-id': clientId } }">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
            >
              + Añadir factura
            </b-button>
          </b-link>
        </template>
        <SaleInvoicesTable
          :client-id="clientId"
          :totals-configuration="saleInvoicesTotalsConfiguration"
        />
      </BaseCard>
    </b-tab>
    <b-tab
      :disabled="!contactsTabVisible"
      title="Personas de contacto"
    >
      <BaseCard
        v-if="contactsTabVisible"
        title="contactos"
        :footer-visible="false"
        actions-visible
      >
        <template #actions>
          <b-link :to="{ name: 'createContact', query: { 'client-id': clientId } }">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
            >
              + Añadir persona de contacto
            </b-button>
          </b-link>
        </template>
        <ContactsTable :client-id="clientId" />
      </BaseCard>
    </b-tab>
  </b-tabs>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import JobsTable from '@/components/jobs/table/JobsTable.vue'
import OffersTable from '@/components/offers/table/OffersTable.vue'
import ContactsTable from '@/components/contacts/table/ContactsTable.vue'
import SaleInvoicesTable from '@/components/sale-invoices/table/SaleInvoicesTable.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ClientAssociatedResourceTabs',
  components: {
    SaleInvoicesTable,
    OffersTable,
    JobsTable,
    BaseCard,
    ContactsTable,
  },
  props: {
    clientId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      saleInvoicesTotalsConfiguration: [
        { colspan: 4 },
        { label: 'TOTAL BRUTO', key: 'total_gross', unit: '€' },
        { label: 'TOTAL RETENCIÓN', key: 'total_retention', unit: '€' },
        { label: 'TOTAL LÍQUIDO', key: 'total_liquid', unit: '€' },
        { label: 'TOTAL', key: 'total_invoice', unit: '€' },
        { colspan: 1 },
      ],
    }
  },
  computed: {
    ...mapGetters('auth', {
      canAccessResource: 'canAccessResource',
    }),
    offersTabVisible() {
      return this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_OFFERS, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
    },
    jobsTabVisible() {
      return this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_JOBS, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
    },
    saleInvoicesTabVisible() {
      return this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_SALE_INVOICES, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
    },
    contactsTabVisible() {
      return this.canAccessResource(this.$data.$constants.RESOURCES.RESOURCE_CONTACTS, this.$data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL)
    },
  },
}
</script>

<style scoped>
</style>
