<template>
  <BaseCard
    :footer-visible="false"
    collapsable
  >
    <template #title>
      <span class="text-uppercase font-weight-bold d-flex align-items-center">
        información bancaria y de pago
      </span>
    </template>
    <ProfilePanel :profile="clientBillingProfile" />
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'

export default {
  name: 'ClientBillingCard',
  components: { ProfilePanel, BaseCard },
  props: {
    client: {
      type: Object,
      default: null,
    },
  },
  computed: {
    clientBillingProfile() {
      if (!this.client) {
        return []
      }

      return [
        { label: 'Forma de pago', value: this.client?.type_payment?.name || 'Sin forma de pago' },
        { label: 'IBAN', value: this.client?.iban || 'Sin datos del IBAN' },
        { label: 'SWIFT', value: this.client?.swift || 'Sin datos del SWIFT' },
        { label: 'IRPF', value: this.client?.irpf_percent || 'Sin datos del IRPF' },
        { label: 'IVA', value: this.client?.tax?.name || 'Sin datos del IVA' },
        { label: 'RECARGO', value: this.client?.surcharge ? `${this.client.surcharge} %` : 'Sin datos del recargo' },
      ]
    },
  },
}
</script>

<style scoped>
</style>
