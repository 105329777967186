<template>
  <BaseCard :footer-visible="false">
    <template #title>
      <span class="text-uppercase font-weight-bold d-flex align-items-center">
        estado riesgo cliente
      </span>

      <b-badge
        v-if="overdueBadgeVisible"
        pill
        variant="light-danger"
        class="mx-1 font-small-3 font-weight-bold px-1 d-inline-flex align-items-center"
      >
        <feather-icon
          icon="InboxIcon"
          size="20"
          color="danger"
        />
        <span class="text-capitalize text-danger badge-text">Facturas pendietes/vencidas</span>
      </b-badge>
    </template>
    <ProfilePanel :profile="profile" />
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import ProfilePanel from '@/components/ui/panel/ProfilePanel.vue'

export default {
  name: 'ClientStatusRiskCard',
  components: { ProfilePanel, BaseCard },
  props: {
    client: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    profile() {
      if (!this.client) {
        return []
      }

      return [
        { label: 'Límite de riesgo', value: this.client?.financial_limit ? `${this.$options.filters.numberToLocalString(this.client.financial_limit)} €` : 'Sin límite de riesgo' },
        { label: 'Facturación viva', value: this.client?.live_billing ?  `${this.$options.filters.numberToLocalString(this.client.live_billing)}` : 'Sin facturación viva' },
      ]
    },
    overdueBadgeVisible() {
      return this.client?.overdue_bills
    },
  },
}
</script>

<style scoped>

</style>
